<template lang="pug">
.Oldest
  .ContainerHeader.pb-5
    .d-flex.align-items-center.justify-content-between.backButton
      img(
        src="@/assets/mobileFSFB/icn_backicn_back.png"
        alt="icn_backicn_back"
        @click="goBack"
      ).icn_backicn_back.ml-3
      h3.fontTitleHeader.m-0 Prioridad en la atención
      img(src="@/assets/mobileFSFB/icn_backicn_back.png" alt="icn_backicn_back").icn_backicn_back.opacity-0.mr-3
  .ContainerData.pt-4.px-2
    h3.yourTurnLabelO {{ selectedArea ? selectedArea.label : "" }}
    p.text-center.textWhiteGray ¿Qué tipo de atención deseas?
    //- h6.subTitle: strong Sin turno preferencial
    ul
      li.liCard.mx-auto.pb-4
        h6.subTitle.mx-auto.text-center.py-2: strong Con turno preferencial
        img(src="@/assets/mobileFSFB/allCards.svg" alt="allCards").allCards.mx-auto.d-block.pt-3.pb-4
        button(@click="turnCall(true)").outlinedBthal.mx-auto.d-block.mb-2
          span Tomar turno preferencial
      li.liCard.mx-auto.mt-3.pb-4
        h6.subTitle.mx-auto.text-center.py-2: strong Sin turno preferencial
        img(src="@/assets/mobileFSFB/desc.svg" alt="desc").desc.mx-auto.d-block.pt-3.pb-4
        button(@click="turnCall(false)").outlinedBthal.buttonBthal.mx-auto.d-block.mb-2.text-center
          span.d-block Sin turno preferencial
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "Oldest",

  computed: {
    ...mapState({
      charging: state => state.mobileFSFB.charging,
      docInfo: state => state.mobileFSFB.docInfo,
      selectedArea: state => state.mobileFSFB.selectedArea
    }),

    toSend() {
      return {
        ...this.docInfo,
        CodigoOficina: "1?area=admisiones_1"
      };
    }
  },

  methods: {
    ...mapActions({
      goBack: "mobileFSFB/goBack",
      goToView: "mobileFSFB/goToView",
      authenticate: "mobileFSFB/authenticate",
      sendTracking: "mobileFSFB/handleTracking",
      turnCall: "mobileFSFB/turnCall"
    }),

    async onContinue() {
      await this.authenticate(this.toSend);

      // tracking
      setTimeout(() => {
        let origin = "mobile_fsfb";
        let auth = this.error
          ? " autentitacion-no-cliente"
          : "autenticacion-cliente";
        let trackingData = {
          uuid: window.person.id,
          accion: auth,
          debmedia_turn_code: "",
          url_origen: window.location.href,
          origen: origin + this.currentSite?.type,
          mensajes: ""
        };

        this.sendTracking(trackingData);
      }, 2000);
    }
  }
};
</script>

<style>
.ContainerHeader {
  background-color: var(--color-radiant-blue);
  margin-bottom: -30px;
}

.Oldest {
  background-color: var(--color-bg-2-white);
  height: 100%;
}

.ContainerData {
  border-radius: 20px 20px 0 0;
  background-color: var(--color-bg-2-white);
  max-height: calc(100vh - 82px);
  overflow-y: auto;
}

.subTitle {
  font-size: 18px;
  line-height: 25px;
  margin: 0 23px;
  font-family: var(--font-secondary);
  color: var(--color-radiant-blue);
}

.fontTitleHeader {
  font-family: var(--font-secondary);
  font-size: 18px;
  line-height: 25px;
  text-align: center;
  color: var(--color-white);
  padding-top: 0px;
}

.liCard {
  width: 325px;
  border-radius: 16px;
  list-style: none;
  box-shadow: 0px 1px 4px rgba(21, 21, 21, 0.02),
    0px 8px 12px rgba(21, 21, 21, 0.08);
  background: var(--color-white);
}

.buttonSelect {
  font-size: 13px;
  line-height: 18px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--color-white);
}

.fontLiCard {
  color: var(--color-gray);
}

.textWhiteGray {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: var(--color-gray);
}

.fontLiCard strong {
  font-family: var(--font-secondary);
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}

.outlinedBthal {
  width: 267px;
  height: 48px;
  background: #ffffff;
  border: 2px solid #009dac;
  border-radius: 16px;
}

.outlinedBthal span {
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: #009dac;
}

.buttonBthal {
  background: #009dac;
  border: 2px solid #009dac;
  border-radius: 16px;
}

.buttonBthal span {
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}

.backButton {
  padding-top: 21px;
}

.yourTurnLabelO {
  font-family: var(--font-secondary);
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #788591;
  margin-bottom: 12.12px;
}
</style>
